
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


















































































































.hub-tv-vod,
[class*='hub-tv-vod--'] {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include mq('m') {
    flex-direction: row;
    justify-content: flex-start;

    &[class*='--reverse'] {
      flex-direction: row-reverse;
    }
  }
}

.hub-tv-vod__infos {
  .price-box {
    box-shadow: none;

    ::v-deep {
      .price-box__misc {
        display: flex;
        align-items: center;
      }

      .btn {
        margin-top: 0;
        margin-left: $spacing * 2;
      }
    }
  }

  @include mq('m') {
    width: col(6);
    margin: 0 $spacing * 1.5;
  }
}

.hub-tv-vod__infos__title {
  @extend %fw-semi;
}

.hub-tv-vod__infos__text {
  margin-bottom: $spacing * 1.5;
}

.hub-tv-vod__infos__tagline {
  @extend %fw-semi;

  display: flex;
  flex-direction: column;
  margin-top: $spacing * 1.5;
  padding-top: $spacing * 1.5;
  border-top: 0.1px solid $c-blue-lightest;
  font-family: $ff-alt;

  em {
    font-style: normal;
  }
}

.hub-tv-vod__movies {
  position: relative;
  height: 100%;
  padding: 1px; // Because of collapsing margins

  @include mq('m') {
    width: col(6);
    margin-left: $spacing * 1.5;

    [class*='hub-tv-vod--'][class*='--reverse'] & {
      margin-right: $spacing * 1.5;
      margin-left: 0;
    }
  }
}

.hub-tv-vod__movies__bubble {
  @extend %color-light;

  position: absolute;
  z-index: layer(low);
  top: 10%;
  right: -25%;
  display: flex;
  flex-direction: column;
  width: inner-col(4, 2);
  padding: $spacing / 4;
  font-family: $ff-alt;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  background-color: $c-pink-medium;
  border-radius: 3px;

  @include mq(m) {
    font-size: 1.4rem;
  }

  @include mq(l) {
    font-size: 1.6rem;
  }

  strong {
    padding-bottom: 0.4rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    margin-top: -6px;
    border: 6px solid;
    border-color: $transparent;
    border-right-color: $c-pink-medium;
  }
}

// [class*='vod__movies__pic'] {
//   box-shadow: 0 0 40px rgba($c-gray-lightest, 0.5);
// }

[class*='vod__movies__pic--small'] {
  position: absolute;
  width: inner-col(6, 2);

  &[class*='--left'] {
    top: 0;
    left: 0;
  }

  &[class*='--right'] {
    right: 0;
    bottom: 0;
  }
}

.hub-tv-vod__movies__pic {
  position: relative;
  width: inner-col(6, 4);
  margin: 10% auto;
  padding: 1px;
  font-size: 0; // Fighting the space between inline-block elements
}

.hub-tv-vod__movies__pic--big {
  position: relative;
  z-index: layer(lower);
}
