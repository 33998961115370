
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




















































































































/* stylelint-disable declaration-no-important */
.combo-card,
[class*='combo-card--'] {
  position: relative;
  border: 1px solid $c-gray-lightest;
  border-radius: 1rem;

  &[class*='--dark'] {
    .combo-card__infos {
      color: $white;
      background-color: $c-blue-dark;
    }
  }

  &.has-link {
    cursor: pointer;

    &:hover {
      .combo-card__picture,
      [class*='combo-card__picture--'] {
        transform: scale(1.1);
      }
    }
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;

    &[class*='--full'],
    [class*='--full'] & {
      flex-direction: row;
      min-height: 30rem;
    }

    &[class*='--reverse'],
    [class*='--full--reverse'] & {
      flex-direction: row-reverse;
    }
  }
}

.combo-card__link {
  @include get-all-space;

  z-index: layer(high);
  margin-top: $spacing;
}

.combo-card__picture-outer,
[class*='combo-card__picture-outer--'] {
  flex-shrink: 0;
  overflow: hidden;

  @include mq(m) {
    [class*='--full'] & {
      width: inner-col(12, 5);
      height: auto;
    }
  }
}

.combo-card__picture {
  width: 100%;
  height: 100%;
  transition: transform 0.3s $ease-out;
}

.combo-card__picture__logo {
  position: absolute;
  z-index: layer(high);
  top: $spacing !important;
  left: $spacing !important;
  max-width: 8rem;
  max-height: 8rem;

  @include mq(m) {
    [class*='--full--reverse'] & {
      right: $spacing * 2 !important;
      left: auto !important;
    }
  }

  @include mq(xxl) {
    top: $spacing * 2 !important;
    left: $spacing * 2 !important;
    max-width: 10rem;
    max-height: 10rem;
  }
}

.combo-card__infos {
  position: relative;
  padding: $spacing * 1.5;

  ::v-deep .btn {
    margin-top: 2rem;
  }

  @include mq(m) {
    [class*='--full'] & {
      width: inner-col(12, 7);
      padding: $spacing * 2;
    }
  }

  @include mq(l) {
    padding: $spacing * 2;

    [class*='--full'] & {
      // price box gets to close to content on NL
      padding: $spacing * 3 $spacing * 3 $spacing * 3 $spacing * 3.5;
    }

    [class*='--full--reverse'] & {
      padding: $spacing * 3;
    }
  }
}

.combo-card__icon {
  height: 3rem;
  margin-bottom: $spacing * 0.5;
  fill: $c-pink-medium;

  @include mq(m) {
    height: 5rem;
  }
}

.combo-card__combo-card__price {
  position: absolute;
  z-index: layer(low);
  top: 0;
  right: $spacing * 2;
  transform: translateY(-50%);

  em {
    font-style: normal;
  }

  @include mq(m) {
    [class*='--full'] & {
      top: $spacing * 2;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateX(-80%);
    }

    [class*='--full--reverse'] & {
      top: $spacing * 2;
      right: 0;
      left: auto;
      transform: translateX(50%);
    }
  }

  @include mq(l) {
    [class*='--full'] & {
      transform: translateX(-50%);
    }

    [class*='--full--reverse'] & {
      transform: translateX(50%);
    }
  }
}

.combo-card__logos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing 0 0;

  div {
    position: relative;
    margin: 0 $spacing;

    & + div {
      &::before {
        @include center-y;

        content: '+';
        left: 0;
        display: block;
        transform: translate(-2rem, -50%);
      }
    }
  }

  svg {
    &:first-child {
      fill: $black;
    }
  }

  @include mq(l) {
    padding: $spacing 0;

    img {
      height: 4rem;
    }
  }
}

.combo-card__title {
  @extend %fw-medium;

  margin: 0 0 $spacing * 0.5;

  ::v-deep .combo-cards & {
    font-size: 1.8em / $body-text-s * 1em;
    line-height: 1.25;

    @include mq(m) {
      font-size: 2.2em / $body-text-m * 1em;
    }

    @include mq(l) {
      font-size: 2.4em / $body-text-l * 1em;
    }
  }
}

.combo-card__subtitle {
  margin: $spacing / 2 0;
}

.combo-card__highlight {
  @extend %fw-black;

  margin: $spacing 0;
  font-family: $ff-alt;
}

// Price box
.combo-card__price-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing / 2 $spacing;
  transform: scale(1.2);
}

.combo-card__price__from,
.combo-card__price__prev {
  @extend %fw-medium;
  @extend %text-uppercase;

  color: $gray-medium;
  font-size: 0.8rem;
}

.combo-card__price__from {
  color: $c-blue-pale;
  font-family: $ff-alt;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.combo-card__price__prev {
  position: relative;
  display: table;
  color: $black;
  font-size: 1.2rem;
}

.combo-card__price__prev--crossed {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform: translate(-50%, -50%);
  }
}

.combo-card__price__price {
  display: flex;
  color: $c-pink-medium;
  font-size: 3.2rem;
  line-height: 1;

  & > small {
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0 0 0.3rem;
    font-size: 1.2rem;

    & :last-child {
      padding-top: 0.2rem;
      color: $gray-medium;
    }
  }
}

.combo-card__price__option {
  max-width: 15rem;
}

.combo-card__price__period {
  ::v-deep em {
    @extend %fw-bold;

    color: $c-orange;
    font-style: normal;
  }
}
