
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
















































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.tv-extra,
[class*='tv-extra--'] {
  position: relative;

  ::v-deep {
    .hero__slides__item__title {
      max-width: 56rem;
    }
    .hero__slides__item__icon .icon .picture {
      max-height: 4.5rem;
    }
    .cards-grid-outer + .cards-grid-outer {
      margin-top: 0;

      > * {
        padding-top: 0 !important;
      }
    }

    .cards-grid__intro {
      @extend %text-left;
    }

    .highlights-row__inner {
      @include mq(xl) {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
      }
    }

    .tv-extra__hero__content__anchors {
      display: flex;
      flex-direction: column;
      margin-top: $spacing;
    }

    .tv-extra__hero__content__anchor {
      @extend %fw-bold;

      display: inline-flex;
      align-items: center;
      color: $c-blue-dark;
      line-height: 1;
      text-decoration: none;

      &:first-child {
        margin-right: $spacing * 1.5;
      }

      .link--revert {
        color: $white;
        background-image: linear-gradient(white, white),
          linear-gradient($c-pink-medium, $c-pink-medium);

        &::after {
          background-color: $white;
        }
      }

      .icon {
        width: 3rem;
        height: 4rem;
        margin-right: $spacing;
        fill: $white;
      }
    }

    .hero__slides__item__content {
      padding-top: $spacing;

      .price-box__price {
        flex-wrap: wrap;
      }

      .price-box__offer {
        font-size: 2.8rem;
      }

      @include mq(l) {
        padding-top: $spacing * 4;
      }
    }
  }
}

.tv-extra__gift {
  ::v-deep {
    .cta__icon {
      fill: $c-pink-medium !important;
      width: 6rem;
      height: 6rem;
    }

    .cta__excerpt {
      max-width: 64rem;

      @include mq(l) {
        font-size: 1.8rem;
      }
    }

    .btn__link__title {
      @include mq(l) {
        font-size: 1.8rem;
      }
    }
  }
}

.tv-extra__gift__logos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing * 2 0 0;

  ::v-deep {
    div {
      margin: 0 $spacing * 1.5;
    }

    svg {
      &:first-child {
        fill: $black;
      }
    }
  }

  @include mq(l) {
    padding: $spacing * 3.5 0 $spacing;

    ::v-deep img {
      height: 5rem;
    }
  }
}
