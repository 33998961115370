
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
































































.combo,
[class*='combo--'] {
  @extend %text-center;
  @include bg-color;

  position: relative;
  padding: $spacing * 2;

  @include mq('m') {
    padding: $spacing * 2.5;
  }

  @include mq('xl') {
    padding: $spacing * 4;
  }
}

.combo__title,
.combo__excerpt {
  @include dark-bgs {
    color: $white;
  }
}

.combo__title {
  margin: 0;
}

.combo__excerpt {
  @extend %fw-light;

  max-width: 56rem;
  margin: 1em auto 0;
  font-family: $ff-default;
  line-height: 1.65;

  @include mq(m) {
    line-height: 1.5;
  }
}

.combo__list,
[class*='combo__list--'] {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: $spacing * 2 auto;

  @include mq(m) {
    flex-direction: row;
  }
}

.combo__list__item {
  width: 100%;
  max-width: 40rem;
  margin: $spacing * 2 $spacing;
}
